<template>
  <div>
    <div
      class="tw-mx-auto tw-flex tw-items-center tw-justify-center tw-h-32 tw-w-32"
    >
      <EmailSent> </EmailSent>
    </div>
    <div class="tw-mt-3 tw-text-center sm:tw-mt-5">
      <h3
        class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
        id="modal-headline"
      >
        Please verify your account
      </h3>
      <div class="tw-mt-2">
        <p class="tw-text-sm tw-text-gray-500">
          Please click the link we've sent to your email to verify your account.
          This link expires after 48 hours.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import EmailSent from "../ui/Illustrations/EmailSent.vue";

export default {
  components: { EmailSent },
};
</script>

<style>
</style>