<template>
  <LoginContainer>
    <div class="tw-mx-auto">
      <div class="tw-mb-10">
        <h2 class="tw-mt-6 tw-text-5xl tw-font-extralight tw-text-orange-500">
          risky.<span class="lg:tw-text-black tw-text-white">live</span>
        </h2>
        <h4 class="tw-mt-2 tw-text-xl tw-font-light lg:tw-text-gray-500 tw-text-white">
          Join your team on risky.live
        </h4>
      </div>

      <div class="tw-mt-6 lg:tw-max-w-xl">
        <div class="tw-mt-1">
          <SignupVerifyMessage v-if="verify_account" class="tw-mb-16" />
          <SignupForm v-else @success="verify_account = true"></SignupForm>
        </div>
        <div class="tw-mt-3">
          <div>
            <router-link
              :to="{ name: 'Login' }"
              class="tw-w-full tw-inline-flex tw-justify-center tw-py-3 tw-px-4 tw-rounded-md lg:tw-bg-gray-100 tw-bg-black-200 lg:hover:tw-bg-gray-200 tw-text-sm tw-leading-5 tw-font-medium lg:tw-text-gray-1000 tw-text-white hover:tw-text-gray-400 focus:tw-outline-none focus:tw-border-gray-300 focus:tw-shadow-outline-gray tw-transition tw-duration-150 tw-ease-in-out"
            >
              <span
                class="lg:tw-text-gray-600 tw-text-white hover:tw-text-gray-400"
                >Already have an account? Sign in</span
              >
              <!-- <icon :icon="['fal', 'external-link-alt']" class="ml-2"></icon> -->
            </router-link>
          </div>
        </div>
        <div class="tw-mt-8 tw-text-center">
          <p
            class="tw-mt-2 tw-text-sm lg:tw-text-gray-600 tw-text-white tw-max-w"
          >
            <a
              href="https://support.prismgroup.io/en/collections/2544137-carelog"
              target="_blank"
              class="tw-font-medium tw-text-orange-500 hover:tw-text-orange-600"
            >
              <span class="tw-text-orange-500 hover:tw-text-orange-600"
                >Having trouble logging in?</span
              >
            </a>
          </p>
        </div>
      </div>
    </div>
  </LoginContainer>
</template>

<script>
import SignupForm from "@/components/Auth/SignupForm.vue";
import LoginContainer from "./LoginContainer.vue";
import SignupVerifyMessage from "../../components/Auth/SignupVerifyMessage.vue";

export default {
  name: "Signup",
  components: {
    SignupForm,
    LoginContainer,
    SignupVerifyMessage,
  },
  data() {
    return {
      verify_account: false,
    };
  },
};
</script>