<template>
  <form method="POST" @submit.prevent="onSubmit" class="tw-space-y-6">
    <div v-if="other_errors.length !== 0">
      <SignupError v-for="(error, i) in other_errors" :message="error" v-bind:key="i"/>
    </div>
    <div class="tw-space-y-1">
      <div class="tw-mt-1">
        <input
          v-model="first_name"
          id="first_name"
          name="first_name"
          type="text"
          placeholder="First name"
          autocomplete="first_name"
          class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200 lg:tw-text-black-500 tw-text-gray-200 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 focus:tw-outline-none sm:tw-text-sm"
          v-bind:class="errors.first_name ? 'tw-border tw-border-red-500' : ''"
        />
      </div>
    </div>

    <div class="tw-space-y-1">
      <div class="tw-mt-1">
        <input
          v-model="last_name"
          id="last_name"
          name="last_name"
          type="text"
          placeholder="Last name"
          autocomplete="last_name"
          class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200 lg:tw-text-black-500 tw-text-gray-200 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 focus:tw-outline-none sm:tw-text-sm"
          v-bind:class="errors.last_name ? 'tw-border tw-border-red-500' : ''"
        />
      </div>
    </div>

    <div>
      <div class="tw-mt-1">
        <vue-tel-input
          v-model="username"
          placeholder="Enter your mobile number"
          mode="international"
          defaultCountry="AU"
          inputClasses="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200 lg:tw-text-black-500 tw-text-gray-200 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 sm:tw-text-sm "
          wrapperClasses="tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200"
          v-bind:style="errors.username
              ? {
                  'border-width': '1px !important',
                  'border-color': 'rgba(239, 68, 68, 1) !important',
                }
              : { 'border-width': '0 !important' }"
        ></vue-tel-input>
      </div>
    </div>

    <div class="tw-space-y-1">
      <div class="tw-mt-1">
        <input
          v-model="password"
          id="password"
          name="password"
          type="password"
          placeholder="Enter your password"
          autocomplete="current-password"
          class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200 lg:tw-text-black-500 tw-text-gray-200 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 focus:tw-outline-none sm:tw-text-sm"
          v-bind:class="errors.password ? 'tw-border tw-border-red-500' : ''"
        />
      </div>
      <PasswordErrorList v-if="password_errors.length !== 0" :errors="password_errors" />
    </div>
    <div>
      <button
        type="submit"
        class="tw-w-full tw-flex tw-justify-center tw-py-3 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white lg:tw-bg-black-500 tw-bg-orange-500 lg:hover:tw-bg-black-300 hover:tw-bg-orange-600 focus:tw-outline-none"
        v-bind:class="[isLoading ? 'disabled:opacity-50' : '']"
      >
        <span :class="[isLoading ? 'd-none' : '']">Create account</span>
        <clip-loader
          :loading="isLoading"
          color="#f8f8f8"
          size="15px"
        ></clip-loader>
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { VueTelInput } from "vue-tel-input";
const Password = () => import("vue-password-strength-meter");
import SignupError from "@/components/Auth/SignupError.vue";
import LoginContainer from "@/views/Auth/LoginContainer";
import PasswordErrorList from "@/components/Auth/PasswordErrorList.vue";

export default {
  name: "Register",
  components: {
    ClipLoader,
    VueTelInput,
    Password,
    LoginContainer,
    PasswordErrorList,
    SignupError
  },
  data() {
    return {
      first_name: "",
      username: "",
      last_name: "",
      invite_token: this.$route.params.token,
      password: null,
      confirm_password: null,
      isLoading: false,
      errors: {},
      password_errors: [],
      other_errors: [],
    };
  },
  methods: {
    validateForm() {
      this.errors = {}; // clear existing errors

      const nameRegex = /^[.|\d|@|-|*|$|/]/,
            mobileRegex = /^[\d+][\d- ]+/;

      if ( !this.first_name || nameRegex.test(this.first_name) ) {
        this.errors.first_name = "invalid value for this field"
      }

      if ( !this.last_name || nameRegex.test(this.last_name) ) {
        this.errors.last_name = "invalid value for this field"
      }

      if ( !this.username || !mobileRegex.test(this.username) ) {
        this.errors.username = "invalid value for this field"
      }

      if ( !this.password ) {
        this.errors.password = "invalid value for this field"
      } else if (this.password.length < 8) {
        this.errors.password = "at least 8 characters required"
      }

      if (Object.keys(this.errors).length === 0) {
        return true;
      } else {
        return false;
      }
    },
    onSubmit() {
      if (this.isLoading === false && this.validateForm()) {
        this.register();
      }
    },
    async register() {
      this.isLoading = true;
      axios
        .post(
          process.env.VUE_APP_API_ROOT + "accounts/accept_invite/",
          this.$data
        )
        .then((response) => {
          this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          // handle authentication and validation errors here
          if (error.response) {
            let errorList = [];
            let errorPasswordList = [];
            const { data } = error.response;

            // if (data.non_field_errors) {
            //   if (Array.isArray(data.non_field_errors)) {
            //     errorList = errorList.concat(data.non_field_errors);
            //   } else {
            //     errorList.push(data.non_field_errors);
            //   }
            // }

            if (data.password) {
              if (Array.isArray(data.password)) {
                errorPasswordList = errorPasswordList.concat(data.password);
              } else {
                errorPasswordList.push(data.password);
              }
            }

            Object.keys(data).forEach(item => {
              // Already handled non-field errors, so we can safely ignore
              if (item == 'non_field_errors') return;

              if (Array.isArray(data[item])) {
                this.errors[item] = data[item][0];
              } else {
                this.errors[item] = data[item];
              }
            });

            // this.other_errors = data.non_field_errors;
            this.password_errors = errorPasswordList;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
.vue-tel-input {
  border-radius: 0.375rem !important;
}
.vti__input {
  border-radius: 0.375rem !important;
  border: unset !important;
}
.vue-tel-input:focus-within {
  box-shadow: none !important;
}
</style>
